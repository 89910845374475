* {
  box-sizing: border-box;
}

html {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 1vw;
  color: #1c1c1c;
  scroll-behavior: smooth;
}

body {
  margin: 0;
}

a {
  text-decoration: none;
  color: #1c1c1c;
}

#root {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

header,
#hero-section,
#aboutUs,
#projects,
#team,
#feedback,
#contact-us,
#footer,
.grid-wrapper {
  width: 80vw;
}

section {
  position: relative;
}

figure {
  padding: 0;
  margin: 0;
}

.sections--header-box {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sections--header {
  font-family: "Inter";
  font-weight: 700;
  font-size: 1vw;
  line-height: 85%;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 1.8%;
}

.sections--decription {
  width: 60vw;
  margin: auto;
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 125%;
  line-height: 2vw;
  text-align: center;
  color: #585757;
  margin-bottom: 3.3%;
}

header {
  position: absolute;
  /* width: 100%;
  height: auto;
  z-index: 3;
  color: #fff; */
  display: flex;
  align-items: center;
  justify-content: center;
}

header .header-appbar {
  background: transparent;
  box-shadow: none;
  padding: 0;
  margin: 0;
  width: 90vw;
  max-width: 100%;
}

header .header-appbar .header-appbar-box {
  padding-left: 0;
  padding-right: 0;
  width: 90vw;
  max-width: 100%;
}

header .header-appbar .header-appbar-box .header-appbar-box-toolbar {
  padding-left: 0;
  padding-right: 0;
  width: 90vw;
  max-width: 100%;
}

header
  .header-appbar
  .header-appbar-box
  .header-appbar-box-toolbar
  .header-appbar-logo {
  width: 15vw;
  margin: 0;
}

header img[alt="logo"] {
  margin-left: 3vw;
  height: 5vw;
  width: auto;
}

#hero-banner {
  background-image: url("hero_image.png");
  width: 100%;
  height: 100vh;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

/* hero-section */

.hero-section-ttl {
  width: 75%;
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 300%;
  margin-bottom: 2vw;
  margin-top: 5vw;
  cursor: default;
  letter-spacing: -0.08vw;
}
.hero-section-par {
  width: 40%;
  display: inline-block;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 1vw;
  color: #ffffff;
  margin-bottom: 1.5vw;
}
.hero-section-btn--cb {
  width: 30%;
  height: auto;
  background-color: #ff7f26;
  color: #ffffff;
  font-family: "Inter";
  text-transform: uppercase;
  font-weight: 700;
  font-size: 150%;
  box-shadow: 0px 0px 20px rgba(161, 101, 243, 0.2);
  border-radius: 0.8vw;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 1vw 0;
}

.hero-section-btn--cb:hover {
  transform: scale(1.1, 1.1);
  cursor: pointer;
}

.hero-section-btn--cb img {
  width: 10%;
}

.scroll {
  width: 5vw;
  height: 5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  position: absolute;
  bottom: 5%;
  right: 5%;
}

.scroll:hover {
  transform: scale(1.1, 1.1);
  cursor: pointer;
}

.scroll img {
  width: 2.6vw;
  height: 4vw;
}

/* #aboutUs */

.aboutUs--ttl {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 235%;
  text-align: center;
  color: #1c1c1c;
  margin: 5vw 0 1vw 0;
}

.aboutUs--p {
  margin-bottom: 6.6%;
}

.grid-wrapper {
  margin: auto;
  margin-bottom: 5vw;
}

.grid-container.three-column {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 5%;
  align-items: baseline;
}

.column--ttl-block {
  display: flex;
  align-items: center;
}
.column--image {
  width: 2.7vw;
  margin-right: 1.2vw;
}
.column--ttl {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  line-height: 1.4vw;
  font-size: 117%;
}
.column--p {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 103%;
  line-height: 2vw;
}

/* projects */

#projects .card {
  padding-bottom: 3vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#projects .card div > figure img {
  width: 23vw;
  height: 250px;
  text-align: center;
  border-radius: 0.5vw;
}

#projects .card .button {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 1.1vw;
  line-height: 1.7vw;
  text-decoration: none;
  color: #ff7f26;
  display: flex;
  align-items: center;
}

#projects .card .button:hover {
  text-decoration: underline;
}

#projects .card .button img {
  width: 2.3%;
  margin-left: 2%;
}

#projects .card div h3 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 1.55vw;
  line-height: 1.2vw;
  color: #000000;
}

#projects .card div h5 {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 0.8vw;
  line-height: 0.5vw;
  color: rgb(150, 150, 150);
}

#projects .card div p {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 1.05vw;
  line-height: 1.55vw;
  color: #000000;
}

/* team */

.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding-top: 3.4%;
}

#team .team-member {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 3.4%;
  padding-bottom: 16%;
}

#team .team-member .info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#team .team-member figure {
  text-align: right;
}

#team .team-member img {
  max-width: 100%;
}

#team .team-member .specialization {
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 97%;
  line-height: 145%;
  color: #585757;
}

#team .team-member .member-name {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 155%;
  line-height: 137%;
  color: #1c1c1c;
  margin-top: 1%;
}

#team .team-member p {
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 97%;
  line-height: 150%;
  color: #585757;
  padding-bottom: 7.5%;
  padding-right: 20%;
}

#team .team-member .contacts a {
  text-decoration: none;
  margin-right: 6%;
}

#team .team-member .contacts a img {
  height: 2em;
  width: auto;
}

/* feedback */

.carousel--elem-navButtonsProps:hover {
  outline: 3px solid rgb(150, 150, 150) !important;
  opacity: 1 !important;
}

#feedback div div .carousel--elem .carousel--elem-navButtonsWrapperProps {
  position: absolute;
  height: 6vw;
  background-color: transparent;
  top: calc(50% - 5vw);
}

#feedback div div .carousel--elem .carousel--elem-navButtonsProps {
  background-color: #ffffff;
  border: 0.1vw solid #dbdbdb;
  color: #969696;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05), 0px 2px 1px rgba(0, 0, 0, 0.03),
    0px 1px 1px rgba(0, 0, 0, 0.04);
}

#feedback
  div
  div
  .carousel--elem
  .carousel--elem-carousel--elem-indicatorIconButtonPropss {
  color: #dbdbdb;
}

#feedback
  div
  div
  .carousel--elem
  .carousel--elem-carousel--elem-activeIndicatorIconButtonProps {
  color: #ff8364;
}

#feedback
  div
  div
  .carousel--elem
  .carousel--elem-carousel--elem-indicatorContainerProps {
  margin-top: 2.6vw;
}

#feedback div div div div div div div div .feedback-card-box {
  width: 100%;
}

#feedback
  div
  div
  div
  div
  div
  div
  div
  div
  .feedback-card-box
  .feedback-card-box-block {
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
}

#feedback div div div div div div div div div div .feedback-body {
  box-shadow: none;
  border: 0.1vw solid #d8d8d8;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2vw;
  gap: 1.1vw;
  border-radius: 1vw;
}

#feedback
  div
  div
  div
  div
  div
  div
  div
  div
  div
  div
  .feedback-body
  .feedback-box-text {
  box-shadow: none;
}

#feedback div div div div div div div div div div div .feedback-text {
  font-family: "Inter";
  font-weight: 400;
  font-size: 1vw;
  line-height: 1.4vw;
  color: #585757;
}

#feedback div div div div div div div div div div div .feedback-rewiever-block {
  box-shadow: none;
  width: 100%;
}

#feedback
  div
  div
  div
  div
  div
  div
  div
  div
  div
  div
  div
  .feedback-rewiever-block
  .feedback-rewiever-block-min {
  padding: 0;
  width: 100%;
}

#feedback
  div
  div
  div
  div
  div
  div
  div
  div
  div
  div
  div
  .feedback-rewiever-block
  .feedback-rewiever-block-min
  .feedback-rewiever-block-flex {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#feedback
  div
  div
  div
  div
  div
  div
  div
  div
  div
  div
  div
  .feedback-rewiever-block
  .feedback-rewiever-block-min
  .feedback-rewiever-block-flex
  div
  .feedback-rewiever-block-padding {
  padding: 0;
}

#feedback
  div
  div
  div
  div
  div
  div
  div
  div
  div
  div
  div
  .feedback-rewiever-block
  .feedback-rewiever-block-min
  .feedback-rewiever-block-flex
  div
  .feedback-rewiever-block-padding
  div
  div
  .feedback-rewiever-img {
  width: 2.6vw;
  height: 2.6vw;
  border-radius: 50%;
}

#feedback
  div
  div
  div
  div
  div
  div
  div
  div
  div
  div
  div
  .feedback-rewiever-block
  .feedback-rewiever-block-min
  .feedback-rewiever-block-flex
  div
  .feedback-rewiever-block-padding
  div
  div
  .feedback-rewiever-name {
  font-family: "Inter";
  font-weight: 600;
  font-size: 1vw;
  line-height: 1.5vw;
  color: #1c1c1c;
}

#feedback
  div
  div
  div
  div
  div
  div
  div
  div
  div
  div
  div
  .feedback-rewiever-block
  .feedback-rewiever-block-min
  .feedback-rewiever-block-flex
  div
  .feedback-rewiever-block-padding
  div
  div
  .feedback-rewiever-position {
  font-family: "Inter";
  font-weight: 400;
  font-size: 0.8vw;
  line-height: 1.1vw;
  color: #969696;
}

#feedback
  div
  div
  div
  div
  div
  div
  div
  div
  div
  div
  div
  .feedback-rewiever-block
  .feedback-rewiever-block-min
  .feedback-rewiever-block-flex
  div
  .feedback-rewiever-logo {
  width: 4vw;
  height: auto;
  border-radius: 0;
}

/* contact-us */

.contact-us--box {
  display: flex;
  justify-content: space-around;
}

.contactUsMapLink {
  position: relative;
}

.contactUsMapBlock {
  width: 30vw;
  height: 40%;
  background-color: #fff;
  z-index: 1;
  position: absolute;
  bottom: -2vw;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  border-radius: 0.5vw;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1.8vw;
}

.contactUsMapBlock--header {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 0.9vw;
  line-height: 1.5vw;
  color: #1c1c1c;
}

.contactUsMapBlock--box-adress,
.contactUsMapBlock--box-mail,
.contactUsMapBlock--box-phone {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 10vw;
}

.contactUsMapBlock--adress-link,
.contactUsMapBlock---phone-link {
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 0.9vw;
  line-height: 1.5vw;
  color: #585757;
}

.contactUsMapBlock--email-link {
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 0.9vw;
  line-height: 1.5vw;
  color: #ff8364;
  white-space: nowrap;
}

.contactUsMapBlock---phone-link {
  white-space: nowrap;
}

.contact-us--box-form {
  width: 27vw;
}

.contact-us--formbox {
  width: 27vw;
}

#contact-us .contactUs-box {
  margin: 3vw 0;
}

#contact-us .contactUs-box .contact-us--box .contactUs-box-left {
  max-width: 38vw;
  max-height: 34.2em;
  margin-right: 4.8vw;
}

#contact-us .contactUs-box .contact-us--box .contactUs-box-right {
  box-shadow: none;
}

#contact-us
  .contactUs-box
  .contact-us--box
  .contactUs-box-right
  .contact-us--formbox
  .contact-us--form-header {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 1.4vw;
  line-height: 1.8vw;
  color: #1c1c1c;
}

#contact-us
  .contactUs-box
  .contact-us--box
  .contactUs-box-right
  .contact-us--formbox
  div
  .contactUs--container-input
  .contactUs--container-input-name {
  margin-bottom: 0.5vw;
}

#contact-us
  .contactUs-box
  .contact-us--box
  .contactUs-box-right
  .contact-us--formbox
  div
  .contactUs--container-input
  .contactUs--container-input-email {
  margin-bottom: 0.5vw;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
}

#contact-us
  .contactUs-box
  .contact-us--box
  .contactUs-box-right
  .contact-us--formbox
  div
  .contactUs--container-input
  .contactUs--container-input-message {
  margin-bottom: 0.5vw;
}

#contact-us
  .contactUs-box
  .contact-us--box
  .contactUs-box-right
  .contact-us--formbox
  div
  .contactUs--container-input
  .contactUs--container-input-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5vw;
  width: 9.5vw;
  height: 2.9vw;
  background: #ff7438;
  border-radius: 0.8vw;
  font-family: "Inter";
  font-weight: 700;
  font-size: 0.9vw;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  white-space: nowrap;
}

/* contact-us */

/* footer */

footer {
  background-color: #f9f9f9;
  width: 100%;
  display: flex;
  justify-content: center;
}

#footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 35vh;
  max-height: 55vh;
}

.footer--left {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.footer--left img {
  max-width: 25vw;
}

.footer--center {
  display: flex;
}

.footer--center-container {
  display: flex;
  flex-direction: column;
  max-width: 25vw;
  gap: 0.4vw;
}
.center--container-header {
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 85%;
  line-height: 140%;
  color: #969696;
}
.center--container-phone {
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 97%;
  line-height: 150%;
  color: #585757;
}
.center--container-email {
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 97%;
  line-height: 150%;
  color: #ff8364;
}
.center--container-adress {
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 97%;
  line-height: 150%;
  color: #585757;
}

.footer--right {
  max-width: 25vw;
}
.footer--right-text {
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 0.9vw;
  line-height: 150%;
  color: #585757;
}
.footer--right-copywriting {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 1vw;
  line-height: 1.5vw;
  color: #1c1c1c;
}

@media all and (max-width: 1200px) {
  header,
  #hero-section,
  #aboutUs,
  #projects,
  #team,
  #feedback,
  #contact-us,
  #footer,
  .grid-wrapper {
    width: 90vw;
  }

  header div .header-appbar-box-toolbar div div div {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 0;
  }

  header
    .header-appbar
    .header-appbar-box
    .header-appbar-box-toolbar
    div
    a
    button {
    font-size: 1.5vw;
    line-height: 2vw;
  }

  header div .header-appbar-box-toolbar div div div div {
    font-size: 1.5vw;
    line-height: 2vw;
  }

  #menu- li {
    font-size: 2vw;
    line-height: 2.5vw;
  }

  .sections--header {
    font-size: 2.5vw;
    margin-bottom: 3vw;
  }

  .sections--decription {
    width: 90vw;
    font-size: 2vw;
    line-height: 3vw;
  }

  /* hero-section */

  .hero-section-ttl {
    font-size: 5vw;
  }

  .hero-section-btn--cb {
    font-size: 2vw;
    line-height: 3vw;
  }

  .scroll {
    width: 7vw;
    height: 7vw;
  }

  .scroll img {
    width: 70%;
    height: 60%;
  }

  /* #aboutUs */

  .aboutUs--ttl {
    font-size: 4vw;
  }

  .grid-wrapper {
    margin: auto;
    margin-bottom: 5vw;
  }

  .column--image {
    width: 5vw;
  }
  .column--ttl {
    font-size: 2vw;
    line-height: 2.5vw;
  }
  .column--p {
    font-size: 1.5vw;
    line-height: 2vw;
  }

  /* projects */

  #projects .card .button {
    font-size: 1.5vw;
    line-height: 2vw;
  }

  #projects .card div h3 {
    font-size: 2vw;
    line-height: 2.5vw;
  }

  #projects .card div h5 {
    font-size: 1vw;
    line-height: 1.5vw;
  }

  #projects .card div p {
    font-size: 1.5vw;
    line-height: 2vw;
  }

  /* team */

  #team .team-member .specialization {
    font-size: 1.5vw;
    line-height: 2vw;
  }

  #team .team-member .member-name {
    font-size: 2vw;
    line-height: 2.5vw;
  }

  #team .team-member p {
    font-size: 1.5vw;
    line-height: 2vw;
  }

  #team .team-member .contacts a img {
    height: 3vw;
  }

  /* feedback */

  #feedback
    div
    div
    div
    div
    div
    div
    div
    div
    .feedback-card-box
    .feedback-card-box-block {
    width: 80%;
    margin: auto;
  }

  #feedback div div div div div div div div div div div .feedback-text {
    font-size: 1.5vw;
    line-height: 2vw;
    margin-bottom: 3vw;
  }

  #feedback
    div
    div
    div
    div
    div
    div
    div
    div
    div
    div
    div
    .feedback-rewiever-block
    .feedback-rewiever-block-min
    .feedback-rewiever-block-flex
    div
    .feedback-rewiever-block-padding
    div
    div
    .feedback-rewiever-img {
    width: 5vw;
    height: 5vw;
  }

  #feedback
    div
    div
    div
    div
    div
    div
    div
    div
    div
    div
    div
    .feedback-rewiever-block
    .feedback-rewiever-block-min
    .feedback-rewiever-block-flex
    div
    .feedback-rewiever-block-padding
    div
    div
    .feedback-rewiever-name {
    font-size: 2vw;
    line-height: 2.5vw;
  }

  #feedback
    div
    div
    div
    div
    div
    div
    div
    div
    div
    div
    div
    .feedback-rewiever-block
    .feedback-rewiever-block-min
    .feedback-rewiever-block-flex
    div
    .feedback-rewiever-block-padding
    div
    div
    .feedback-rewiever-position {
    font-size: 1.5vw;
    line-height: 2vw;
  }

  #feedback
    div
    div
    div
    div
    div
    div
    div
    div
    div
    div
    div
    .feedback-rewiever-block
    .feedback-rewiever-block-min
    .feedback-rewiever-block-flex
    div
    .feedback-rewiever-logo {
    width: 5vw;
  }

  /* contact-us */

  #contact-us .err-contactUs div div div div {
    font-size: 2vw;
    line-height: 2.5vw;
  }

  #contact-us .err-contactUs div div div div div button svg {
    width: 2vw;
    height: 2vw;
  }

  .contactUsMapBlock--header {
    font-size: 1.5vw;
    line-height: 2vw;
  }

  .contactUsMapBlock {
    width: 35vw;
    height: 45%;
    padding: 1vw;
    gap: 1vw;
  }

  .contactUsMapBlock--box-adress,
  .contactUsMapBlock--box-mail,
  .contactUsMapBlock--box-phone {
    width: 19vw;
  }

  .contactUsMapBlock--adress-link,
  .contactUsMapBlock---phone-link {
    font-size: 1.5vw;
    line-height: 2vw;
  }

  .contactUsMapBlock--email-link {
    font-size: 1.5vw;
    line-height: 2vw;
  }

  #contact-us
    .contactUs-box
    .contact-us--box
    .contactUs-box-right
    .contact-us--formbox
    .contact-us--form-header {
    font-size: 2vw;
    line-height: 2.5vw;
  }

  #contact-us
    .contactUs-box
    .contact-us--box
    .contactUs-box-right
    .contact-us--formbox
    div
    .contactUs--container-input
    .contactUs--container-input-name
    label {
    font-size: 1.5vw;
    line-height: 2vw;
  }

  #contact-us
    .contactUs-box
    .contact-us--box
    .contactUs-box-right
    .contact-us--formbox
    div
    .contactUs--container-input
    .contactUs--container-input-name
    div
    input {
    font-size: 1.5vw;
    line-height: 2vw;
  }

  #contact-us
    .contactUs-box
    .contact-us--box
    .contactUs-box-right
    .contact-us--formbox
    div
    .contactUs--container-input
    .contactUs--container-input-name
    legend {
    font-size: 1.5vw;
    line-height: 1.5vw;
  }

  #contact-us
    .contactUs-box
    .contact-us--box
    .contactUs-box-right
    .contact-us--formbox
    div
    .contactUs--container-input
    .contactUs--container-input-email
    label {
    font-size: 1.5vw;
    line-height: 2vw;
  }

  #contact-us
    .contactUs-box
    .contact-us--box
    .contactUs-box-right
    .contact-us--formbox
    div
    .contactUs--container-input
    .contactUs--container-input-email
    div
    input {
    font-size: 1.5vw;
    line-height: 2vw;
  }

  #contact-us
    .contactUs-box
    .contact-us--box
    .contactUs-box-right
    .contact-us--formbox
    div
    .contactUs--container-input
    .contactUs--container-input-email
    legend {
    font-size: 1.5vw;
    line-height: 1.5vw;
  }

  #contact-us
    .contactUs-box
    .contact-us--box
    .contactUs-box-right
    .contact-us--formbox
    div
    .contactUs--container-input
    .contactUs--container-input-message
    label {
    font-size: 1.5vw;
    line-height: 2vw;
  }

  #contact-us
    .contactUs-box
    .contact-us--box
    .contactUs-box-right
    .contact-us--formbox
    div
    .contactUs--container-input
    .contactUs--container-input-message
    div
    textarea {
    font-size: 1.5vw;
    line-height: 2vw;
  }

  #contact-us
    .contactUs-box
    .contact-us--box
    .contactUs-box-right
    .contact-us--formbox
    div
    .contactUs--container-input
    .contactUs--container-input-message
    legend {
    font-size: 1.5vw;
    line-height: 1.5vw;
  }

  #contact-us
    .contactUs-box
    .contact-us--box
    .contactUs-box-right
    .contact-us--formbox
    div
    .contactUs--container-input
    .contactUs--container-input-button {
    font-size: 1vw;
    line-height: 1.5vw;
  }

  /* contact-us */

  /* footer */

  #footer {
    min-height: 40vh;
    max-height: 70vh;
    width: 90vw;
    flex-wrap: wrap;
    gap: 5vw;
    padding: 5vw 0;
  }

  .footer--left {
    max-width: 30vw;
  }

  .footer--left img {
    max-width: 30vw;
  }

  .footer--center {
    display: flex;
    max-width: 55vw;
    margin: auto;
  }

  .footer--center-container {
    display: flex;
    flex-direction: column;
    max-width: 55vw;
    gap: 0.4vw;
  }
  .center--container-header {
    font-size: 1.5vw;
    line-height: 2vw;
  }
  .center--container-phone {
    font-size: 2vw;
    line-height: 2.5vw;
  }
  .center--container-email {
    font-size: 2vw;
    line-height: 2.5vw;
  }
  .center--container-adress {
    font-size: 2vw;
    line-height: 2.5vw;
  }

  .footer--right {
    max-width: 90vw;
    margin: auto;
  }
  .footer--right-text {
    font-size: 1.5vw;
    line-height: 2vw;
  }
  .footer--right-copywriting {
    font-size: 2vw;
    line-height: 2.5vw;
  }
}

@media all and (max-width: 912px) {
  header,
  #hero-section,
  #aboutUs,
  #projects,
  #team,
  #feedback,
  #contact-us,
  #footer,
  .grid-wrapper {
    width: 100vw;
  }

  header
    .header-appbar
    .header-appbar-box
    .header-appbar-box-toolbar
    .header-appbar-logo {
    width: 35vw;
    height: auto;
  }

  header div .header-appbar-box-toolbar div div div {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 0;
  }

  header div .header-appbar-box-toolbar div div div div {
    font-size: 5vw;
    line-height: 6vw;
    margin-right: 2vw;
  }

  header div .header-appbar-box-toolbar div div div svg {
    font-size: 8vw;
  }

  #block-lang-menu-pos button svg {
    height: 8vw;
    width: 8vw;
  }

  #menu-appbar li {
    font-size: 5vw;
    line-height: 6vw;
  }

  #menu- li {
    font-size: 5vw;
    line-height: 6vw;
  }

  .sections--header {
    font-size: 6vw;
    margin-bottom: 7vw;
  }

  .sections--decription {
    width: 90vw;
    font-size: 4vw;
    line-height: 5vw;
  }

  /* hero-section */

  .hero-section-ttl {
    margin: auto;
    width: 95vw;
    font-size: 8vw;
  }

  .hero-section-btn--cb {
    width: 50vw;
    font-size: 3vw;
    line-height: 4vw;
    padding: 2vw 0;
    margin-left: 3vw;
    margin-top: 3vw;
  }

  .scroll {
    width: 15vw;
    height: 15vw;
  }

  .scroll img {
    width: 70%;
    height: 60%;
  }

  /* #aboutUs */

  .aboutUs--ttl {
    font-size: 7vw;
  }

  .aboutUs--p {
    margin-bottom: 6.6%;
  }

  .grid-wrapper {
    margin: auto;
    margin-bottom: 5vw;
  }

  .grid-container.three-column {
    display: grid;
    grid-template-columns: repeat(1, 100vw);
    grid-column-gap: 5%;
  }

  .aboutUs--column {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .column--ttl-block {
    display: flex;
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    align-items: center;
  }
  .column--image {
    width: 20vw;
  }
  .column--ttl {
    font-size: 5vw;
    line-height: 6vw;
  }
  .column--p {
    width: 90vw;
    font-size: 4vw;
    line-height: 5vw;
  }

  /* projects */

  #projects .card {
    padding-bottom: 3vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  #projects .card div > figure img {
    width: 100vw;
    height: 250px;
    text-align: center;
    border-radius: 0.5vw;
  }

  #projects .card .button {
    font-size: 4vw;
    line-height: 5vw;
  }

  #projects .card div h3,
  #projects .card div h5,
  #projects .card div p {
    width: 90vw;
  }

  #projects .card div h3,
  #projects .card div h5,
  #projects .card div p,
  #projects .card .button {
    margin-left: 3vw;
  }

  #projects .card div h3 {
    font-size: 5vw;
    line-height: 6vw;
  }

  #projects .card div h5 {
    font-size: 3vw;
    line-height: 4vw;
  }

  #projects .card div p {
    font-size: 4vw;
    line-height: 5vw;
  }

  /* team */

  .grid-container {
    grid-template-columns: repeat(1, 100vw);
  }

  #team .team-member {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  #team .team-member figure {
    width: 50vw;
    margin-bottom: 5vw;
  }

  #team .team-member .info {
    width: 90vw;
    margin: auto;
  }

  #team .team-member img {
    max-width: 50vw;
  }

  #team .team-member .specialization {
    font-size: 4vw;
    line-height: 5vw;
  }

  #team .team-member .member-name {
    font-size: 6vw;
    line-height: 7vw;
  }

  #team .team-member p {
    font-size: 4vw;
    line-height: 5vw;
    padding: 0;
  }

  #team .team-member .contacts a img {
    height: 5vw;
  }

  /* feedback */

  #feedback div div .carousel--elem .carousel--elem-navButtonsWrapperProps {
    display: none;
  }

  #feedback div div div div div div div div .feedback-card-box {
    width: 100%;
  }

  #feedback
    div
    div
    div
    div
    div
    div
    div
    div
    .feedback-card-box
    .feedback-card-box-block {
    width: 90%;
    margin: auto;
  }

  #feedback div div div div div div div div div div div .feedback-text {
    font-size: 4vw;
    line-height: 5vw;
    margin-bottom: 3vw;
  }

  #feedback
    div
    div
    div
    div
    div
    div
    div
    div
    div
    div
    div
    .feedback-rewiever-block
    .feedback-rewiever-block-min
    .feedback-rewiever-block-flex
    div
    .feedback-rewiever-block-padding
    div
    div
    .feedback-rewiever-img {
    width: 12vw;
    height: 12vw;
  }

  #feedback
    div
    div
    div
    div
    div
    div
    div
    div
    div
    div
    div
    .feedback-rewiever-block
    .feedback-rewiever-block-min
    .feedback-rewiever-block-flex
    div
    .feedback-rewiever-block-padding
    div
    div
    .feedback-rewiever-name {
    font-size: 5vw;
    line-height: 6vw;
  }

  #feedback
    div
    div
    div
    div
    div
    div
    div
    div
    div
    div
    div
    .feedback-rewiever-block
    .feedback-rewiever-block-min
    .feedback-rewiever-block-flex
    div
    .feedback-rewiever-block-padding
    div
    div
    .feedback-rewiever-position {
    font-size: 4vw;
    line-height: 5vw;
  }

  #feedback
    div
    div
    div
    div
    div
    div
    div
    div
    div
    div
    div
    .feedback-rewiever-block
    .feedback-rewiever-block-min
    .feedback-rewiever-block-flex
    div
    .feedback-rewiever-logo {
    width: 12vw;
  }

  /* contact-us */

  #contact-us .err-contactUs div div div div {
    font-size: 4vw;
    line-height: 5vw;
  }

  #contact-us .err-contactUs div div div div div button svg {
    width: 4vw;
    height: 4vw;
  }

  .contact-us--box {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #contact-us .contactUs-box .contact-us--box div .contactUs-box-left {
    max-width: 912px;
    max-height: 100vh;
    width: 90vw;
    height: auto;
    margin: auto;
  }

  .contactUsMapLink img {
    width: 90vw;
  }

  .contactUsMapBlock {
    width: 80%;
    height: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 3vw;
  }

  .contactUsMapBlock--header {
    font-size: 4vw;
    line-height: 5vw;
  }

  .contactUsMapBlock--box-adress,
  .contactUsMapBlock--box-mail,
  .contactUsMapBlock--box-phone {
    width: 45%;
  }

  .contactUsMapBlock--box-mail {
    margin-bottom: 1.7vw;
  }

  .contactUsMapBlock--adress-link,
  .contactUsMapBlock---phone-link {
    font-size: 3vw;
    line-height: 4vw;
  }

  .contactUsMapBlock--email-link {
    font-size: 3vw;
    line-height: 4vw;
  }

  .contact-us--box-form {
    width: 90vw;
  }

  .contact-us--formbox {
    width: 90vw;
  }

  #contact-us .contactUs-box .contact-us--box div .contactUs-box-right {
    max-width: 912px;
    max-height: 100vh;
    width: 90vw;
    height: auto;
    margin: auto;
  }

  #contact-us
    .contactUs-box
    .contact-us--box
    .contactUs-box-right
    .contact-us--formbox
    .contact-us--form-header {
    font-size: 5vw;
    line-height: 6vw;
  }

  #contact-us
    .contactUs-box
    .contact-us--box
    .contactUs-box-right
    .contact-us--formbox
    div
    .contactUs--container-input
    .contactUs--container-input-name {
    margin-bottom: 1vw;
  }

  #contact-us
    .contactUs-box
    .contact-us--box
    .contactUs-box-right
    .contact-us--formbox
    div
    .contactUs--container-input
    .contactUs--container-input-name
    label {
    font-size: 4vw;
    line-height: 5vw;
  }

  #contact-us
    .contactUs-box
    .contact-us--box
    .contactUs-box-right
    .contact-us--formbox
    div
    .contactUs--container-input
    .contactUs--container-input-name
    div
    input {
    font-size: 4vw;
    line-height: 5vw;
  }

  #contact-us
    .contactUs-box
    .contact-us--box
    .contactUs-box-right
    .contact-us--formbox
    div
    .contactUs--container-input
    .contactUs--container-input-name
    legend {
    font-size: 4vw;
    line-height: 4vw;
  }

  #contact-us
    .contactUs-box
    .contact-us--box
    .contactUs-box-right
    .contact-us--formbox
    div
    .contactUs--container-input
    .contactUs--container-input-email {
    margin-bottom: 1vw;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 0;
  }

  #contact-us
    .contactUs-box
    .contact-us--box
    .contactUs-box-right
    .contact-us--formbox
    div
    .contactUs--container-input
    .contactUs--container-input-email
    label {
    font-size: 4vw;
    line-height: 5vw;
  }

  #contact-us
    .contactUs-box
    .contact-us--box
    .contactUs-box-right
    .contact-us--formbox
    div
    .contactUs--container-input
    .contactUs--container-input-email
    div
    input {
    font-size: 4vw;
    line-height: 5vw;
  }

  #contact-us
    .contactUs-box
    .contact-us--box
    .contactUs-box-right
    .contact-us--formbox
    div
    .contactUs--container-input
    .contactUs--container-input-email
    legend {
    font-size: 4vw;
    line-height: 4vw;
  }

  #contact-us
    .contactUs-box
    .contact-us--box
    .contactUs-box-right
    .contact-us--formbox
    div
    .contactUs--container-input
    .contactUs--container-input-message {
    margin-bottom: 1vw;
  }

  #contact-us
    .contactUs-box
    .contact-us--box
    .contactUs-box-right
    .contact-us--formbox
    div
    .contactUs--container-input
    .contactUs--container-input-message
    label {
    font-size: 4vw;
    line-height: 5vw;
  }

  #contact-us
    .contactUs-box
    .contact-us--box
    .contactUs-box-right
    .contact-us--formbox
    div
    .contactUs--container-input
    .contactUs--container-input-message
    div
    textarea {
    font-size: 4vw;
    line-height: 5vw;
  }

  #contact-us
    .contactUs-box
    .contact-us--box
    .contactUs-box-right
    .contact-us--formbox
    div
    .contactUs--container-input
    .contactUs--container-input-message
    legend {
    font-size: 4vw;
    line-height: 4vw;
  }

  #contact-us
    .contactUs-box
    .contact-us--box
    .contactUs-box-right
    .contact-us--formbox
    div
    .contactUs--container-input
    .contactUs--container-input-button {
    gap: 15%;
    width: 40vw;
    height: auto;
    font-size: 4vw;
    line-height: 5vw;
  }

  /* contact-us */

  /* footer */

  #footer {
    min-height: 30vh;
    max-height: 75vh;
    width: 90vw;
    flex-wrap: wrap;
    gap: 5vw;
    padding: 5vw 0;
  }

  .footer--left {
    max-width: 30vw;
  }

  .footer--left img {
    max-width: 30vw;
  }

  .footer--center {
    display: flex;
    max-width: 55vw;
    margin: auto;
  }

  .footer--center-container {
    display: flex;
    flex-direction: column;
    max-width: 55vw;
    gap: 0.4vw;
  }
  .center--container-header {
    font-size: 4vw;
    line-height: 5vw;
  }
  .center--container-phone {
    font-size: 4vw;
    line-height: 4vw;
  }
  .center--container-email {
    font-size: 4vw;
    line-height: 4vw;
  }
  .center--container-adress {
    font-size: 4vw;
    line-height: 4vw;
  }

  .footer--right {
    max-width: 90vw;
    margin: auto;
  }
  .footer--right-text {
    font-size: 3vw;
    line-height: 4vw;
  }
  .footer--right-copywriting {
    font-size: 5vw;
    line-height: 6vw;
  }
}

#map {
  height: 400px;
  /* The height is 400 pixels */
  width: 100%;
  /* The width is the width of the web page */
}
